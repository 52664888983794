@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.btn {
  width: max-content;
  display: inline-block;
  margin-left: 1250px;
  margin-top: -150px;
  color: #0c0cbe;
  background-color: #ec7e35;
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid #1f1f38;
  transition: all 400ms ease;
  z-index: -50;
}
.btn:hover{
  background: #f03110;
  color: #0c0cbe;
  border-color: transparent;
}
@media screen and (max-width: 600px){
  .btn{
    /* width: 100%; */
    margin-left: 230px;
    margin-top: -180px;
  }
}

@media screen and (max-width: 1024px){
  .btn{
    /* width: 100%; */
    margin-left: 600px;
    margin-top: -100px;
  }
}