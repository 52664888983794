.card{
    margin: 3px;
    box-shadow:0px 6px 7px -5px;
  
  
}
.card-cont{
    display: flex;
    align-items: center;

}
/* -------------------------------------------------------- */

 .quote-card{
    padding: 4rem;
    height: 100%;
    overflow: hidden;
    background-image: radial-gradient(
       farthest-corner at 40px 40px,
       rgba(247, 158, 14, 0.883) 40%,
       rgb(255, 217, 121) 70%
    );
    position: relative;
    display: flex;
    align-items: center;
 }
 
 blockquote {
    color: rgba(0, 0, 0, 0.6);
    font-family: Tahoma, sans-serif;
    font-size: 1rem;
 
    width: 80%;
    margin: 0 auto;
 }
 blockquote h1 {
    font-size: 4rem;
 }
 blockquote p {
    font-weight: bold;
    font-style: italic;
    margin-bottom: 0;
 }
 
 blockquote p::before {
    content: "“";
    font-family: Georgia;
    font-size: 10rem;
    margin: -2rem 0 0 -2rem;
    position: absolute;
    top:0.5rem;
    left:2.4rem;
    
    opacity: 0.5;
 }

 blockquote p::after {
    content: "”";
    font-family: Georgia;
    font-size: 10rem;
    position: absolute;
    bottom:-6rem;
    right: 2rem;
    opacity: 0.5;
 }
 

 
 blockquote cite {
    position: absolute;
    right: 7rem;
    bottom:1rem;
    font-size: 1.3rem;
 }
 